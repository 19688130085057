import Orator from './orator'

export default class RemoteOrator extends Orator {
  constructor() {
    super()
    this.type = 'RemoteOrator'
    this.isVideoOn = false
    this.isMicOn = false
    this.visible = true
    this.streamControls = null
    this.isHost = false
  }
}
