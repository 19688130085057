<template lang="pug">
div.the-stream
  div.the-stream__container(v-if="!isMobileDevice")
    div.the-stream__column(v-show='currentRoom === "waiting"')
      v-waiting-room(:orators='remoteOrators' ref='orators')
        div.orator-display(
            ref="display"
            :class="{ 'show-background': !isVideoOn }"
          )
        //- video.orator-display(
        //-     ref="display2"
        //-     :class="{ 'show-background': !isVideoOn }"
        //-   )
    div.the-stream__column.the-stream__column_player
      div.the-stream__video-container
        v-pill(v-if="currentRoom === 'live'") Вы в прямом эфире
        v-pill(v-else color="#54BDEA") Просмотр прямого эфира
        div.the-stream__video-container__remote-orators(v-show="currentRoom === 'live'")
          div.the-stream__video-container__item(
            v-for="orator in remoteOrators"
            :key='orator.name'
            v-show="orator.visible"
            ref="participant"
            :data-name="orator.name"
            :class="{ 'show-background': !orator.isVideoOn }"
          )
            span.the-stream__video-container__item__name {{ orator.name.replace(/-[^-]*$/, '') }}
        div.the-stream__video-container__presentation-image(
          v-if="presentation.totalPages && currentRoom === 'live'"
        )
          img(
            v-for="(image, index) in presentation.origins"
            :class="{ hidden: index + 1 !== presentation.currentPage }"
            :src="image"
          )
          v-presentation-controls(
            v-if="presentation.totalPages"
            :current-page="presentation.currentPage"
            :total-pages="presentation.totalPages"
            @go-next-page="goNextPage"
            @go-prev-page="goPrevPage"
          )
        div.the-stream__video-container__stream-preview(
          v-show="sourcePreview"
          ref="source_preview"
        )
          video(id="sourcePreview")
        div.the-stream__player-footer
          div.the-stream__settings
            v-player-settings(
              @show-settings="showSettingsModal"
              @on-mic-state-change="micToggle"
              @on-video-state-change="videoToggle")
    div.the-stream__column_preview
      v-preview
        div.the-stream__local-display(ref="stream_display")
      div.chat
        div#hyper-chat
  div.the-stream__container-mobile(v-else)
    div.the-stream__video-container
      div.the-stream__video-container__remote-orators(v-show="currentRoom === 'live' && !presentation.totalPages")
        div.the-stream__video-container__item(
          v-for="orator in remoteOrators"
          :key='orator.name'
          v-show="orator.visible"
          ref="participant"
          :data-name="orator.name"
          :class="{ 'show-background': !orator.isVideoOn }"
        )
          span.the-stream__video-container__item__name {{ orator.name.replace(/-[^-]*$/, '') }}
      div.the-stream__video-container__presentation-image(
        v-if="presentation.totalPages"
      )
        img(
          v-for="(image, index) in presentation.origins"
          :class="{ hidden: index + 1 !== presentation.currentPage }"
          :src="image"
        )
        v-presentation-controls(
          v-if="presentation.totalPages"
          :current-page="presentation.currentPage"
          :total-pages="presentation.totalPages"
          @go-next-page="goNextPage"
          @go-prev-page="goPrevPage"
        )
      div.the-stream__video-container__stream-preview(
        v-show="sourcePreview"
        ref="stream_display"
      )
    div.the-stream__orators(v-show='currentRoom === "waiting"')
      v-waiting-room(:orators='remoteOrators' ref='orators')
        div.orator-display(
            ref="display"
            :class="{ 'show-background': !isVideoOn }"
          )
    div.the-stream__menu-footer
      v-player-settings(
        @show-settings="showSettingsModal"
        @on-mic-state-change="micToggle"
        @on-video-state-change="videoToggle"
        @toggle-chat-panel="toggleChatPanel")
    div.the-stream__chat(v-show="isChatPanelVisible")
        div.chat__title
          span Чат
          div.chat__close(@click='toggleChatPanel')
            v-icon.icon(icon="XIcon" size="20")
        div.chat
          div#hyper-chat

  v-settings-change(
    v-if="isSettingsModalVisible"
    is-modal
    @on-settings-change="onSettingsChange"
  )
  v-loader(:isShow='!online' :text='offlineLoaderText')
</template>

<script>
import * as RoomApi from '@flashphoner/websdk/src/room-module'

import LocalOrator from '../models/local-orator'
import RemoteOrator from '../models/remote-orator'
import axios from 'axios'
import MESSAGE_EVENTS from '@/constants/message-events'
import { mapGetters, mapActions } from 'vuex'

const Flashphoner = RoomApi.sdk
const STREAM_STATUS = Flashphoner.constants.STREAM_STATUS
const SESSION_STATUS = Flashphoner.constants.SESSION_STATUS
const STREAM_EVENT = Flashphoner.constants.STREAM_EVENT
const STREAM_EVENT_TYPE = Flashphoner.constants.STREAM_EVENT_TYPE
const ROOM_EVENT = RoomApi.events

import resourceMain from '../../config'

export default {
  name: 'TheStream',
  components: {
    VPresentationControls: () => import('@/components/v-presentation-controls'),
    VPlayerSettings: () => import('@/components/v-player-settings'),
    VPreview: () => import('@/components/v-preview'),
    VSettingsChange: () => import('@/components/v-settings-change'),
    VPill: () => import('@/components/v-pill'),
    VWaitingRoom: () => import('@/components/v-waiting-room.vue'),
    VLoader: () => import('@/components/v-loader.vue'),
  },
  data: () => ({
    streams: [],
    presentation: {
      totalPages: null,
      currentPage: 1,
      origins: [],
      id: null,
    },
    options: {
      unmutePlayOnStart: false,
      transport: 'TCP',
    },
    activeHostSceneParticipants: [],
    socketServerURL: null,
    publishedStream: null,
    session: null,
    isPopupOpen: false,
    hidden: null,
    room: null,
    localOrator: {},
    remoteOrators: [],
    myStream: null,
    streamPreview: null,
    sourcePreview: false,
    mainAudioMix: null,
    connectedOrators: [],
    isChatPanelVisible: false,
    activeScenePayload: null,
    currentSceneOrators: [],
    online: navigator.onLine,
    offlineLoaderText:
      'У вас возникли проблемы с интернет-подключением. Студия Пруфикс продолжает попытки подключения к серверу...',
  }),
  computed: {
    ...mapGetters('devices', [
      'isVideoOn',
      'isMicOn',
      'getDevices',
      'getCurrentDevicesId',
    ]),
    ...mapGetters('studio', ['getCurrentRoom']),
    ...mapGetters('video', [
      'getCanvasStream',
      'getCameraStream',
      'getBgType',
      'getStream',
    ]),
    currentRoom: {
      get() {
        return this.getCurrentRoom
      },
    },
    isSettingsModalVisible() {
      return this.$store.state.isSettingsModalVisible
    },
    isStreaming() {
      return this.$store.state.isStreaming
    },
    isMobileDevice() {
      return this.$isMobile
    },
    allOrators() {
      return [this.localOrator, ...this.remoteOrators]
    },
  },
  watch: {
    isStreaming(value) {
      if (!value) {
        this.myStream.stop()
        this.room.leave()
      }
    },
    isMicOn(value) {
      this.room?.getParticipants().forEach((participant) => {
        participant.sendMessage(
          JSON.stringify({
            orator: encodeURIComponent(this.localOrator.name),
            type: value
              ? MESSAGE_EVENTS.UNMUTE_ORATOR
              : MESSAGE_EVENTS.MUTE_ORATOR,
          })
        )
      })
    },
  },
  created() {
    Flashphoner.init({})
    window.addEventListener('offline', this.onOffline)
    window.addEventListener('online', this.onOnline)
  },
  destroyed() {
    window.removeEventListener('offline', this.onOffline)
    window.removeEventListener('online', this.onOnline)
  },
  async mounted() {
    await this.getConnectionSettings()

    if (this.getBgType === 'blur') {
      this.localOrator.stream = this.getCanvasStream
    } else {
      this.localOrator.stream = await this.getAccessToDevices()
    }
    // this.localOrator.audioSourceMic =
    //   this.$store.state.audioContext.createMediaStreamSource(
    //     this.localOrator.stream
    //   )

    // this.mainAudioMix = this.$store.state.audioContext.createGain();
    // this.mainAudioMix.connect(this.$store.state.audioContext.destination);

    document.addEventListener('visibilitychange', () => {
      this.hidden = document.hidden
    })

    await this.connect()

    console.log('the-stream :: mounted')

    // if (!this.isMobileDevice) {
    setInterval(() => {
      if (
        !this.isMobileDevice ||
        (this.isMobileDevice && !this.sourcePreview)
      ) {
        if (!this.streamPreview?.isRemoteAudioMuted()) {
          this.streamPreview?.muteRemoteAudio()
        }
      }
      // console.log(this.streamPreview?.isRemoteAudioMuted());
    }, 1000)
    // }
  },
  methods: {
    ...mapActions('devices', [
      'toggleMic',
      'toggleVideo',
      'getAccessToDevices',
    ]),
    ...mapActions('studio', ['setCurrentRoom']),
    ...mapActions(['showLoader', 'hideLoader']),
    async onOffline() {
      this.online = false
      await this.session.disconnect()
    },
    async onOnline() {
      this.online = true
      await this.connect()
    },
    goNextPage() {
      console.log('[goNextPage]')
      const roomParticipants = this.room.getParticipants()
      const host = roomParticipants.find((stream) => {
        console.log('streamName', stream.getStreams()[0].streamName())
        return stream.getStreams()[0]?.streamName().includes('is-host')
      })
      host.sendMessage(
        JSON.stringify({
          type: MESSAGE_EVENTS.PRESENTATION_PAGE_FORWARD,
          payload: {
            id: this.presentation.id,
            totalPages: this.presentation.totalPages,
            currentPage:
              this.presentation.currentPage === this.presentation.totalPages
                ? this.presentation.totalPages
                : this.presentation.currentPage + 1,
          },
        })
      )
      // roomParticipants.forEach((participant) => {
      //   participant.sendMessage(
      //     JSON.stringify({
      //       type: MESSAGE_EVENTS.PRESENTATION_PAGE_FORWARD,
      //       payload: {
      //         id: this.presentation.id,
      //         totalPages: this.presentation.totalPages,
      //         currentPage:
      //           this.presentation.currentPage === this.presentation.totalPages
      //             ? this.presentation.totalPages
      //             : this.presentation.currentPage + 1,
      //       },
      //     })
      //   )
      // })
    },
    goPrevPage() {
      console.log('[goPrevPage]')
      const roomParticipants = this.room.getParticipants()
      const host = roomParticipants.find((stream) => {
        return stream.getStreams()[0].streamName().includes('is-host')
      })
      host.sendMessage(
        JSON.stringify({
          type: MESSAGE_EVENTS.PRESENTATION_PAGE_BACKWARD,
          payload: {
            id: this.presentation.id,
            totalPages: this.presentation.totalPages,
            currentPage:
              this.presentation.currentPage === 1
                ? 1
                : this.presentation.currentPage - 1,
          },
        })
      )
      // const roomParticipants = this.room.getParticipants()
      // roomParticipants.forEach((participant) => {
      //   participant.sendMessage(
      //     JSON.stringify({
      //       type: MESSAGE_EVENTS.PRESENTATION_PAGE_BACKWARD,
      //       payload: {
      //         id: this.presentation.id,
      //         totalPages: this.presentation.totalPages,
      //         currentPage:
      //           this.presentation.currentPage === 1
      //             ? 1
      //             : this.presentation.currentPage - 1,
      //       },
      //     })
      //   )
      // })
    },
    showSettingsModal() {
      this.$store.commit('UPDATE_MODAL_SETTINGS_VISIBLE', true)
    },
    async connect() {
      console.log('[connect]')
      try {
        this.session = await RoomApi.connect({
          urlServer: this.socketServerURL,
          username: encodeURIComponent(this.localOrator.name),
        })
          .on(SESSION_STATUS.ESTABLISHED, (session) => {
            console.log(`the-stream :: connect ${session.status()}`)
            this.joinRoom()
          })
          .on(SESSION_STATUS.FAILED, (session) => {
            console.log(`the-stream :: connect ${session.status()}`)
          })
          .on(SESSION_STATUS.DISCONNECTED, (session) => {
            console.log(`the-stream :: connect ${session.status()}`)
          })
      } catch (e) {
        console.error(e, 'connect')
      }
      window.reconnect = this.onChangeName
    },
    async onChangeName(name) {
      this.localOrator.name = name
      try {
        await this.session.disconnect()
        this.connect()
      } catch (e) {
        console.error(e, 'connect')
      }
    },
    sdpHook(sdp) {
      //увеличиваем ширину канала до 10 мбит/сек
      // let sdpStringFind1 = "c=IN (.*)\r\n";
      // let sdpStringReplace1 = "c=IN $1\r\nb=AS:10000\r\n";
      //чтобы быстрее разогнать битрейт используем x-google-min/max-bitrate
      let sdpStringFind2 = 'a=fmtp:(.*) (.*)'
      let sdpStringReplace2 =
        'a=fmtp:$1 $2;x-google-start-bitrate=1000;x-google-max-bitrate=1000;x-google-min-bitrate=1000'
      let newSDP = sdp.sdpString.toString()
      // newSDP = newSDP.replace(
      //   new RegExp(sdpStringFind1, "g"),
      //   sdpStringReplace1
      // );
      newSDP = newSDP.replace(
        new RegExp(sdpStringFind2, 'g'),
        sdpStringReplace2
      )
      return newSDP
    },
    // getCurrentRoomParticipants() {
    //   return this.room.getParticipants().filter((participant) => {
    //     if (this.currentRoom === 'live') {
    //       const hasHost = this.activeScenePayload?.sceneParticipants.find(
    //         (el) => el.includes('is-host')
    //       )
    //       if (hasHost) {
    //         return true
    //       } else {
    //         return participant
    //           .getStreams()
    //           .find((stream) => !stream.streamName().includes('is-host'))
    //       }
    //     } else if (this.currentRoom === 'waiting') {
    //       if (this.activeScenePayload) {
    //         const hasHost = this.activeScenePayload.sceneParticipants.find(
    //           (el) => el.includes('is-host')
    //         )
    //         if (hasHost) {
    //           return participant
    //             .getStreams()
    //             .find((stream) => !stream.streamName().includes('is-host'))
    //         } else {
    //           return true
    //         }
    //       } else {
    //         return true
    //       }
    //     }
    //   })
    // },
    // async joinLiveRoom() {
    //   try {
    //     await this.session
    //       .join({ name: this.$store.state.query.workspace })
    //       .on(ROOM_EVENT.STATE, (room) => {
    //         this.room = room

    //         this.room.getParticipants().forEach((participant) => {
    //           const remoteOrator = this.remoteOrators.find(
    //             (el) => el.name === decodeURIComponent(participant.name())
    //           )
    //           remoteOrator?.streamControls?.muteRemoteAudio()
    //           participant.getStreams().forEach((participant_stream) => {
    //             if (!participant_stream.streamName().includes('is-stream')) {
    //               // participant_stream.stop()
    //               // participant_stream.muteRemoteAudio()
    //               const video = document.getElementById(participant_stream.id())
    //               video?.pause()
    //             }
    //           })
    //         })

    //         room.getParticipants().forEach((participant) => {
    //           participant.getStreams().forEach((participant_stream) => {
    //             if (participant_stream.streamName().includes('is-stream')) {
    //               console.log(participant_stream)
    //               participant_stream
    //                 .play(this.$refs.stream_display)
    //                 .on(STREAM_STATUS.PENDING, (stream) => {
    //                   console.log(
    //                     `${
    //                       STREAM_STATUS.PENDING
    //                     } content stream of ${participant.name()}`,
    //                     stream,
    //                     stream.id()
    //                   )
    //                 })
    //                 .on(STREAM_STATUS.PLAYING, (stream) => {
    //                   console.log(
    //                     `${
    //                       STREAM_STATUS.PLAYING
    //                     } content stream of ${participant.name()}`,
    //                     stream,
    //                     stream.id()
    //                   )
    //                   // if (!this.isMobileDevice) {
    //                   stream.muteRemoteAudio()
    //                   // }
    //                   this.streamPreview = stream
    //                   // if (!this.isMobileDevice) {
    //                   this.streamPreview.muteRemoteAudio()
    //                   if (this.activeScenePayload?.sceneParticipants) {
    //                     this.setActiveSceneVisibility(this.activeScenePayload)
    //                   } else {
    //                     let sceneParticipants = this.room
    //                       .getParticipants()
    //                       .map((p) => p.name())
    //                     sceneParticipants.push(
    //                       encodeURIComponent(this.localOrator.name)
    //                     )

    //                     this.setActiveSceneVisibility({
    //                       sceneParticipants,
    //                     })
    //                   }
    //                   // }
    //                 })
    //             } else {
    //               // let remoteOrator = new RemoteOrator()

    //               // remoteOrator.name = decodeURIComponent(participant.name())

    //               // this.remoteOrators.push(remoteOrator)

    //               const alreadyHaveParticipantName = this.remoteOrators.find(
    //                 (el) => el.name === decodeURIComponent(participant.name())
    //               )

    //               if (!alreadyHaveParticipantName) {
    //                 console.log('push 1')
    //                 let remoteOrator = new RemoteOrator()

    //                 remoteOrator.name = decodeURIComponent(participant.name())

    //                 this.remoteOrators.push(remoteOrator)
    //               }

    //               const remoteOrator = this.remoteOrators.find(
    //                 (el) => el.name === decodeURIComponent(participant.name())
    //               )

    //               this.$nextTick(() => {
    //                 const display = this.$refs.participant.find(
    //                   (el) =>
    //                     el.dataset.name ===
    //                     decodeURIComponent(participant.name())
    //                 )

    //                 participant_stream
    //                   .play(display)
    //                   .on(STREAM_EVENT, (streamEvent) => {
    //                     console.log(streamEvent, 'STREAM EVENT')
    //                     switch (streamEvent.type) {
    //                       case STREAM_EVENT_TYPE.VIDEO_MUTED:
    //                         this.onVideoMuted(remoteOrator, false)
    //                         break
    //                       case STREAM_EVENT_TYPE.VIDEO_UNMUTED:
    //                         this.onVideoMuted(remoteOrator, true)
    //                         break
    //                     }
    //                   })
    //                   .on(STREAM_STATUS.PENDING, (stream) => {
    //                     console.log(
    //                       `${
    //                         STREAM_STATUS.PENDING
    //                       } remote participant stream of ${participant.name()}`,
    //                       stream,
    //                       stream.id()
    //                     )
    //                   })
    //                   .on(STREAM_STATUS.PLAYING, (stream) => {
    //                     console.log(
    //                       `${
    //                         STREAM_STATUS.PLAYING
    //                       } remote participant stream of ${participant.name()}`,
    //                       stream,
    //                       stream.id()
    //                     )
    //                     // stream.setVolume(0);
    //                     stream.muteRemoteAudio()
    //                     remoteOrator.streamControls = stream
    //                     remoteOrator.streamControls.muteRemoteAudio()
    //                     remoteOrator.isVideoOn = !(
    //                       remoteOrator.streamControls.getVideoState()?.muted ||
    //                       false
    //                     )

    //                     setTimeout(() => {
    //                       if (this.activeScenePayload?.sceneParticipants) {
    //                         this.setActiveSceneVisibility(
    //                           this.activeScenePayload
    //                         )
    //                       } else {
    //                         let sceneParticipants = this.room
    //                           .getParticipants()
    //                           .map((p) => p.name())
    //                         sceneParticipants.push(
    //                           encodeURIComponent(this.localOrator.name)
    //                         )

    //                         this.setActiveSceneVisibility({
    //                           sceneParticipants,
    //                         })
    //                       }
    //                     }, 1000)

    //                     setTimeout(() => {
    //                       const video = document.getElementById(stream.id())

    //                       if (video) {
    //                         video.load()
    //                         remoteOrator.stream = video.srcObject
    //                         remoteOrator.audioSourceMic =
    //                           this.$store.state.audioContext.createMediaStreamSource(
    //                             video.srcObject
    //                           )
    //                       }
    //                     }, 0)
    //                   })
    //               })
    //             }
    //           })
    //         })
    //         this.myStream?.stop()
    //         this.publishLocalMedia(room)
    //       })
    //       .on(ROOM_EVENT.JOINED, (participant) => {
    //         console.log(ROOM_EVENT.JOINED, participant)
    //         //TODO сюда добавляем нового подключившегося участника
    //         const alreadyHaveParticipantName = this.remoteOrators.find(
    //           (el) => el.name === decodeURIComponent(participant.name())
    //         )

    //         if (!alreadyHaveParticipantName) {
    //           console.log('push 2')
    //           let remoteOrator = new RemoteOrator()

    //           remoteOrator.name = decodeURIComponent(participant.name())

    //           this.remoteOrators.push(remoteOrator)
    //         }

    //         this.remoteOrators.forEach((orator) => {
    //           orator.visible = false
    //           orator.streamControls?.muteRemoteAudio()
    //         })

    //         setTimeout(() => {
    //           if (this.activeScenePayload?.sceneParticipants) {
    //             this.setActiveSceneVisibility(this.activeScenePayload)
    //           } else {
    //             let sceneParticipants = this.room
    //               .getParticipants()
    //               .map((p) => p.name())
    //             sceneParticipants.push(
    //               encodeURIComponent(this.localOrator.name)
    //             )

    //             this.setActiveSceneVisibility({
    //               sceneParticipants,
    //             })
    //           }
    //         }, 1000)
    //       })
    //       .on(ROOM_EVENT.LEFT, (participant) => {
    //         console.log(ROOM_EVENT.LEFT, participant)
    //         console.log('splice 1')
    //         this.remoteOrators.splice(
    //           this.remoteOrators.findIndex(
    //             (el) => el.name === decodeURIComponent(participant.name())
    //           ),
    //           1
    //         )
    //         if (!this.activeScenePayload) {
    //           let sceneParticipants = this.room
    //             .getParticipants()
    //             .map((p) => p.name())
    //           sceneParticipants.push(encodeURIComponent(this.localOrator.name))

    //           this.setActiveSceneVisibility({
    //             sceneParticipants,
    //           })
    //         }
    //       })
    //       .on(ROOM_EVENT.PUBLISHED, (participant) => {
    //         console.log(ROOM_EVENT.PUBLISHED, participant)
    //         participant.getStreams().forEach((participant_stream) => {
    //           if (participant_stream.streamName().includes('is-stream')) {
    //             participant_stream
    //               .play(this.$refs.stream_display)
    //               .on(STREAM_STATUS.PENDING, (stream) => {
    //                 console.log(
    //                   `${
    //                     STREAM_STATUS.PENDING
    //                   } content stream of ${participant.name()}`,
    //                   stream,
    //                   stream.id()
    //                 )
    //               })
    //               .on(STREAM_STATUS.PLAYING, (stream) => {
    //                 console.log(
    //                   `${
    //                     STREAM_STATUS.PLAYING
    //                   } content stream of ${participant.name()}`,
    //                   stream,
    //                   stream.id()
    //                 )
    //                 // if (!this.isMobileDevice) {
    //                 stream.muteRemoteAudio()
    //                 // }
    //                 this.streamPreview = stream
    //                 // if (!this.isMobileDevice) {
    //                 this.streamPreview.muteRemoteAudio()
    //                 if (this.activeScenePayload?.sceneParticipants) {
    //                   this.setActiveSceneVisibility(this.activeScenePayload)
    //                 } else {
    //                   let sceneParticipants = this.room
    //                     .getParticipants()
    //                     .map((p) => p.name())
    //                   sceneParticipants.push(
    //                     encodeURIComponent(this.localOrator.name)
    //                   )

    //                   this.setActiveSceneVisibility({
    //                     sceneParticipants,
    //                   })
    //                 }
    //                 // }
    //               })
    //           } else {
    //             this.$nextTick(() => {
    //               this.onPublishedRemoteOrator(
    //                 participant,
    //                 participant_stream,
    //                 'live'
    //               )
    //             })
    //           }
    //         })
    //       })
    //       .on(ROOM_EVENT.MESSAGE, (message) => {
    //         const event = JSON.parse(message.text)

    //         switch (event.type) {
    //           case MESSAGE_EVENTS.PRESENTATION_APPEAR:
    //             this.presentation.totalPages = event.payload.totalPages
    //             this.presentation.currentPage = event.payload.currentPage
    //             this.presentation.id = event.payload.id
    //             this.presentation.origins = event.payload.origins
    //             break
    //           case MESSAGE_EVENTS.PRESENTATION_PAGE_FORWARD:
    //           case MESSAGE_EVENTS.PRESENTATION_PAGE_BACKWARD:
    //             this.presentation.totalPages = event.payload.totalPages
    //             this.presentation.currentPage = event.payload.currentPage
    //             this.presentation.id = event.payload.id
    //             break
    //           case MESSAGE_EVENTS.PRESENTATION_DISAPPEAR:
    //             console.log(event)
    //             this.presentation.totalPages = null
    //             this.presentation.id = null
    //             this.presentation.origins = []
    //             break
    //           case MESSAGE_EVENTS.ACTIVE_SCENE_UPDATE: {
    //             console.log('ACTIVE_SCENE_UPDATE')
    //             this.activeScenePayload = { ...event.payload }
    //             this.sourcePreview = false
    //             this.remoteOrators.forEach((orator) => {
    //               orator.visible = true
    //             })
    //             const localOratorOnScene = event.payload.sceneParticipants.find(
    //               (p) => decodeURIComponent(p) === this.localOrator.name
    //             )
    //             if (localOratorOnScene) {
    //               let orators = this.activeScenePayload.sceneParticipants.map(
    //                 (el) => el.replace('is-host', '')
    //               )
    //               this.room.getParticipants().forEach((participant) => {
    //                 if (orators.includes(participant.name())) {
    //                   participant.getStreams().forEach((participant_stream) => {
    //                     if (
    //                       !participant_stream.streamName().includes('is-stream')
    //                     ) {
    //                       this.$nextTick(() => {
    //                         // this.onPublishedRemoteOrator(
    //                         //   participant,
    //                         //   participant_stream,
    //                         //   'live'
    //                         // )
    //                         // participant_stream.muteRemoteAudio()
    //                         const remoteOrator = this.remoteOrators.find(
    //                           (el) =>
    //                             el.name ===
    //                             decodeURIComponent(participant.name())
    //                         )
    //                         remoteOrator?.streamControls?.muteRemoteAudio()
    //                         const video = document.getElementById(
    //                           participant_stream.id()
    //                         )
    //                         video?.pause()
    //                       })
    //                     }
    //                   })
    //                 }
    //               })
    //             } else {
    //               let orators = this.activeScenePayload.sceneParticipants.map(
    //                 (el) => el.replace('is-host', '')
    //               )
    //               this.room.getParticipants().forEach((participant) => {
    //                 if (orators.includes(participant.name())) {
    //                   participant.getStreams().forEach((participant_stream) => {
    //                     if (
    //                       !participant_stream.streamName().includes('is-stream')
    //                     ) {
    //                       // participant_stream.stop()
    //                       // participant_stream.muteRemoteAudio()
    //                       const remoteOrator = this.remoteOrators.find(
    //                         (el) =>
    //                           el.name === decodeURIComponent(participant.name())
    //                       )
    //                       remoteOrator?.streamControls?.muteRemoteAudio()
    //                       const video = document.getElementById(
    //                         participant_stream.id()
    //                       )
    //                       video?.pause()
    //                     }
    //                   })
    //                 }
    //               })
    //             }
    //             setTimeout(() => {
    //               this.setActiveSceneVisibility(this.activeScenePayload)
    //             }, 1000)
    //             break
    //           }
    //           case MESSAGE_EVENTS.MUTE_ORATOR:
    //             this.toggleMic(false)
    //             break
    //           case MESSAGE_EVENTS.UNMUTE_ORATOR:
    //             this.toggleMic(true)
    //             break
    //           case MESSAGE_EVENTS.CHANGE_ROOM:
    //             console.log(event.payload.room)
    //             this.activeScenePayload = null
    //             this.changeRoomToWaiting()
    //             break
    //         }
    //       })
    //       .on(ROOM_EVENT.FAILED, (participant) => {
    //         console.log(RoomApi.events.FAILED, participant)
    //       })
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    onStreamPlay(stream) {
      console.log('[onStreamPlay]')
      stream
        .play(this.$refs.stream_display, this.options)
        .on(STREAM_STATUS.PENDING, (stream) => {
          console.log(
            `${STREAM_STATUS.PENDING} content stream`,
            stream,
            stream.id()
          )
        })
        .on(STREAM_STATUS.PLAYING, (stream) => {
          console.log(
            `${STREAM_STATUS.PLAYING} content stream`,
            stream,
            stream.id()
          )
          const video = this.$refs.source_preview.children[0]
          video.volume = 0.4
          // if (!this.isMobileDevice) {
          // stream.muteRemoteAudio()
          // }
          this.streamPreview = stream
          console.log(this.streamPreview)
          if (this.currentRoom != 'live') {
            this.translatePreviewStream()
          }
          // if (!this.isMobileDevice) {
          // this.streamPreview.muteRemoteAudio()
          // if (this.activeScenePayload?.sceneParticipants) {
          //   this.setActiveSceneVisibility(this.activeScenePayload)
          // } else {
          //   let sceneParticipants = this.room
          //     .getParticipants()
          //     .map((p) => p.name())
          //   sceneParticipants.push(
          //     encodeURIComponent(this.localOrator.name)
          //   )

          //   this.setActiveSceneVisibility({
          //     sceneParticipants,
          //   })
          // }
          // setTimeout(() => {
          //   this.showWaitingRoomParticipants()
          // }, 1000)
          // }
        })
    },
    async joinRoom() {
      console.log('[joinRoom]')
      this.remoteOrators = []
      try {
        await this.session
          .join({ name: this.$store.state.query.workspace })
          .on(ROOM_EVENT.STATE, (room) => {
            this.room = room
            console.log('ROOM_EVENT.STATE')
            // this.room.getParticipants().forEach((participant) => {
            //   const remoteOrator = this.remoteOrators.find(
            //     (el) => el.name === decodeURIComponent(participant.name())
            //   )
            //   remoteOrator?.streamControls?.muteRemoteAudio()
            //   participant.getStreams().forEach((participant_stream) => {
            //     if (!participant_stream.streamName().includes('is-stream')) {
            //       // participant_stream.stop()
            //       // participant_stream.muteRemoteAudio()
            //       const video = document.getElementById(participant_stream.id())
            //       video?.pause()
            //     }
            //   })
            // })

            room.getParticipants().forEach((participant) => {
              if (participant.name() == 'render-server') {
                const mainStream = participant.getStreams()[0]
                if (mainStream) this.onStreamPlay(mainStream)
              } else {
                participant.getStreams().forEach((participant_stream) => {
                  const remoteOrator = new RemoteOrator()
                  remoteOrator.name = decodeURIComponent(participant.name())
                  if (participant_stream.streamName().includes('is-host')) {
                    remoteOrator.isHost = true
                  }

                  this.remoteOrators.push(remoteOrator)

                  this.$nextTick(() => {
                    const display = this.$refs.orators.$refs.orator.find(
                      (el) =>
                        el.dataset.name ===
                        decodeURIComponent(participant.name())
                    )

                    participant_stream
                      .play(display, this.options)
                      .on(STREAM_EVENT, (streamEvent) => {
                        console.log(streamEvent, 'STREAM EVENT')
                        switch (streamEvent.type) {
                          case STREAM_EVENT_TYPE.VIDEO_MUTED:
                            this.onVideoMuted(remoteOrator, false)
                            break
                          case STREAM_EVENT_TYPE.VIDEO_UNMUTED:
                            this.onVideoMuted(remoteOrator, true)
                            break
                        }
                      })
                      .on(STREAM_STATUS.PENDING, (stream) => {
                        console.log(
                          `${
                            STREAM_STATUS.PENDING
                          } remote participant stream of ${decodeURIComponent(
                            participant.name()
                          )}`,
                          stream,
                          stream.id()
                        )
                      })
                      .on(STREAM_STATUS.PLAYING, (stream) => {
                        console.log(
                          `${
                            STREAM_STATUS.PLAYING
                          } remote participant stream of ${decodeURIComponent(
                            participant.name()
                          )}`,
                          stream,
                          stream.id()
                        )
                        // stream.setVolume(0);
                        // stream.muteRemoteAudio()
                        remoteOrator.streamControls = stream
                        remoteOrator.streamControls.unmuteRemoteAudio()
                        remoteOrator.isVideoOn = !(
                          remoteOrator.streamControls.getVideoState()?.muted ||
                          false
                        )
                        remoteOrator.isMicOn = !(
                          remoteOrator.streamControls.getAudioState()?.muted ||
                          false
                        )

                        // const video = document.getElementById(stream.id())

                        // if (video) {
                        // video.load()
                        // remoteOrator.stream = video.srcObject
                        // remoteOrator.audioSourceMic =
                        //   this.$store.state.audioContext.createMediaStreamSource(
                        //     video.srcObject
                        //   )

                        // const display = this.$refs.participant
                        //   .find((el) => {
                        //     return (
                        //       el.dataset.name ===
                        //       decodeURIComponent(participant.name())
                        //     )
                        //   })
                        //   .querySelector('video')

                        // display.srcObject = video.srcObject.clone()
                        // display.play()
                        // }

                        // remoteOrator.visible = true
                        // remoteOrator.streamControls?.unmuteRemoteAudio()

                        // this.showWaitingRoomParticipants()
                      })
                  })
                })
              }
            })
            // проверка количества участников через секунду после подключения, если вдруг участники подключились одновременно
            setTimeout(() => {
              if (
                room.getParticipants().length - 1 !==
                this.remoteOrators.length
              ) {
                const newParticipiant = room
                  .getParticipants()
                  .filter((orator) => {
                    const remoteNames = this.remoteOrators.map(
                      (elem) => elem.name
                    )
                    if (decodeURIComponent(orator.name()) === 'render-server') {
                      return false
                    }
                    return !remoteNames.includes(
                      decodeURIComponent(orator.name())
                    )
                  })
                console.log(newParticipiant[0])
                if (newParticipiant[0]) {
                  this.addNewParticipant(newParticipiant[0])
                }
              }
            }, 1000)
            this.publishLocalMedia(room)
          })
          .on(ROOM_EVENT.JOINED, (participant) => {
            console.log(ROOM_EVENT.JOINED, participant)
            this.addNewParticipant(participant)

            // const remoteOrator = this.remoteOrators.find(
            //   (el) => el.name === decodeURIComponent(participant.name())
            // )

            // remoteOrator.visible = false
            // remoteOrator.streamControls?.muteRemoteAudio()

            // this.showWaitingRoomParticipants()
            // }
          })
          .on(ROOM_EVENT.LEFT, (participant) => {
            console.log(ROOM_EVENT.LEFT, participant)
            if (participant.name() === 'render-server') {
              this.onStopPublished()
            } else {
              const remoteOratorIndex = this.remoteOrators.findIndex(
                (el) => el.name === decodeURIComponent(participant.name())
              )
              const remoteOrator = this.remoteOrators[remoteOratorIndex]
              remoteOrator.visible = false
              remoteOrator.streamControls?.muteRemoteAudio()
              //не работает
              remoteOrator.streamControls?.stop()

              this.remoteOrators.splice(remoteOratorIndex, 1)

              // this.showWaitingRoomParticipants()
            }
          })
          .on(ROOM_EVENT.PUBLISHED, (participant) => {
            console.log(ROOM_EVENT.PUBLISHED, participant)
            if (participant.name() == 'render-server') {
              const mainStream = participant.getStreams()[0]
              if (mainStream) this.onStreamPlay(mainStream)
            } else {
              this.$nextTick(() => {
                participant.getStreams().forEach((participant_stream) => {
                  this.onPublishedRemoteOrator(participant, participant_stream)

                  participant.getStreams().forEach((participant_stream) => {
                    if (participant_stream.streamName().includes('is-host')) {
                      const remoteOratorIndex = this.remoteOrators.findIndex(
                        (el) =>
                          el.name === decodeURIComponent(participant.name())
                      )
                      const remoteOrator = this.remoteOrators[remoteOratorIndex]
                      remoteOrator.isHost = true
                    }
                  })
                })
              })
            }
          })
          .on(ROOM_EVENT.MESSAGE, (message) => {
            const event = JSON.parse(message.text)
            console.log(event.type)
            switch (event.type) {
              case MESSAGE_EVENTS.MUTE_ORATOR:
                console.log(event)
                if (!event.orator) {
                  this.toggleMic(false)
                } else {
                  const orator = this.remoteOrators.find(
                    (o) => o.name === decodeURIComponent(event.orator)
                  )
                  orator.isMicOn = false
                }
                break
              case MESSAGE_EVENTS.UNMUTE_ORATOR:
                console.log(event)
                if (!event.orator) {
                  this.toggleMic(true)
                } else {
                  const orator = this.remoteOrators.find(
                    (o) => o.name === decodeURIComponent(event.orator)
                  )
                  orator.isMicOn = true
                }
                break
              case MESSAGE_EVENTS.CHANGE_ROOM:
                this.changeRoom({ ...event.payload })
                break
              case MESSAGE_EVENTS.PRESENTATION_APPEAR:
                console.log('MESSAGE_EVENTS.PRESENTATION_APPEAR')
                this.presentation.totalPages = event.payload.totalPages
                this.presentation.currentPage = event.payload.currentPage
                this.presentation.id = event.payload.id
                this.presentation.origins = event.payload.origins
                break
              case MESSAGE_EVENTS.PRESENTATION_PAGE_FORWARD:
              case MESSAGE_EVENTS.PRESENTATION_PAGE_BACKWARD:
                this.presentation.totalPages = event.payload.totalPages
                this.presentation.currentPage = event.payload.currentPage
                this.presentation.id = event.payload.id
                break
              case MESSAGE_EVENTS.PRESENTATION_DISAPPEAR:
                this.presentation.totalPages = null
                this.presentation.id = null
                this.presentation.origins = []
                break
              case MESSAGE_EVENTS.ACTIVE_SCENE_UPDATE:
                this.sceneUpdate({ ...event.payload })
                // this.changeRoom({ ...event.payload })
                break
              case MESSAGE_EVENTS.RENAME:
                if (event.payload.isHost) {
                  const host = this.remoteOrators.find(
                    (orator) => orator.isHost === true
                  )
                  host.name = decodeURIComponent(event.payload.newName)
                } else {
                  this.onChangeName(decodeURIComponent(event.payload.newName))
                }
                break
              case MESSAGE_EVENTS.STOP_PUBLISHING:
                this.onStopPublished()
                break
            }
          })
          .on(ROOM_EVENT.FAILED, (participant) => {
            console.log(RoomApi.events.FAILED, participant)
          })
      } catch (e) {
        console.log(e)
      }
    },
    addNewParticipant(participant) {
      if (participant?.name() !== 'render-server') {
        //TODO сюда добавляем нового подключившегося участника
        const alreadyHaveParticipantName = this.remoteOrators.find(
          (el) => el.name === decodeURIComponent(participant.name())
        )

        if (!alreadyHaveParticipantName) {
          const remoteOrator = new RemoteOrator()
          remoteOrator.name = decodeURIComponent(participant.name())

          this.remoteOrators.push(remoteOrator)
        }
      }
    },
    sceneUpdate({
      orators,
      hasVideo = false,
      hasPresentation = false,
      hasScreenSharing = false,
    }) {
      console.log('[sceneUpdate]')

      orators = orators?.map((orator) => decodeURIComponent(orator))

      this.currentSceneOrators = orators

      console.log(orators)

      if (hasVideo || hasScreenSharing) {
        // if (!this.sourcePreview) {
        this.translatePreviewStream()
        // }
      }

      if (hasPresentation) {
        this.remoteOrators.forEach((orator) => {
          if (this.currentRoom !== 'waiting') {
            // orator.visible = false
            // orator.streamControls?.muteRemoteAudio()
            if (orators?.includes(orator.name)) {
              orator.visible = true
              const video = this.$refs.participant.find(
                (el) => el.dataset.name === orator.name
              )
              this.playOratorStream(video, orator.name)
            } else {
              orator.visible = false
              const video = this.$refs.participant
                .find((el) => el.dataset.name === orator.name)
                ?.querySelector('video')
              video?.pause()
            }
          }

          const video = this.$refs.participant
            .find((el) => el.dataset.name === orator.name)
            ?.querySelector('video')
          video?.pause()
        })

        if (!orators.includes(this.localOrator.name) && orators.length) {
          // if (!this.sourcePreview) {
          setTimeout(() => {
            this.translatePreviewStream()
          }, 1000)
          // }
        } else if (!orators.length) {
          // if (!this.sourcePreview) {
          setTimeout(() => {
            this.translatePreviewStream()
          }, 1000)
          // }
        } else {
          if (this.sourcePreview) {
            this.stopPreviewStream()
          }
        }
      } else {
        if (!orators?.includes(this.localOrator.name)) {
          this.remoteOrators.forEach((orator) => {
            const video = this.$refs.participant
              .find((el) => el.dataset.name === orator.name)
              ?.querySelector('video')
            video?.pause()
          })

          // if (!this.sourcePreview) {
          this.translatePreviewStream()
          // }
        } else {
          if (this.sourcePreview) {
            this.stopPreviewStream()
          }

          this.remoteOrators.forEach((orator) => {
            if (orators?.includes(orator.name)) {
              orator.visible = true
              // orator.streamControls?.unmuteRemoteAudio()

              const video = this.$refs.participant.find(
                (el) => el.dataset.name === orator.name
              )

              // video.play()
              this.playOratorStream(video, orator.name)
            } else {
              orator.visible = false
              // orator.streamControls?.muteRemoteAudio()

              const video = this.$refs.participant
                .find((el) => el.dataset.name === orator.name)
                ?.querySelector('video')
              video?.pause()
            }
          })
        }
      }
      this.updateRemoteOratorsAudio()
    },
    stopOratorStream(oratorName) {
      let oldStremIndex = -1
      const oldStream = this.streams.find((elem, index) => {
        oldStremIndex = index
        console.log(elem.name())
        return decodeURIComponent(elem.name()).includes(oratorName)
      })
      if (this.streams !== -1 && oldStream) {
        console.log('[stopOratorStream ' + oldStream.name() + ']')
        oldStream.stop()
        this.streams.splice(oldStremIndex, 1)
      }
    },
    playOratorStream(display, oratorName) {
      this.stopOratorStream(oratorName)
      const participant = this.room
        .getParticipants()
        .find(
          (participant) => decodeURIComponent(participant.name()) === oratorName
        )
      display.querySelectorAll('video').forEach((elem) => elem.remove())
      const stream = participant
        .getStreams()[0]
        .play(display, this.options)
        .on(STREAM_EVENT, (streamEvent) => {
          console.log(streamEvent)
        })
        .on(STREAM_STATUS.PENDING, () => {
          console.log('participant PENDING')
        })
        .on(STREAM_STATUS.PLAYING, (stream) => {
          console.log('participant PLAYING')
          stream.unmuteRemoteAudio()
        })
        .on(STREAM_STATUS.UNPUBLISHED, () => {
          console.log('participant UNPUBLISHED')
        })
        .on(STREAM_STATUS.PUBLISHING, () => {
          console.log('participant PUBLISHING')
        })
        .on(STREAM_STATUS.FAILED, (stream) => {
          console.log('participant FAILED')
          console.log(stream.getInfo())
        })
      this.streams.push(stream)
    },
    updateRemoteOratorsAudio() {
      console.log('[updateRemoteOratorsAudio]')
      console.log(this.currentSceneOrators)
      this.remoteOrators.forEach((orator) => {
        const oratorIsLive = this.currentSceneOrators?.includes(orator.name)
        const localOratorIsLive = this.currentSceneOrators?.includes(
          this.localOrator.name
        )
        if (localOratorIsLive && oratorIsLive) {
          return orator.streamControls?.unmuteRemoteAudio()
        }
        if (!localOratorIsLive && !oratorIsLive) {
          return orator.streamControls?.unmuteRemoteAudio()
        }
        return orator.streamControls?.muteRemoteAudio()
        // if (this.currentRoom === 'live') {
        //   if (oratorIsLive) {
        //     orator.streamControls?.unmuteRemoteAudio()
        //   } else {
        //     // setTimeout(
        //     orator.streamControls?.muteRemoteAudio()
        //     // 500 + 200 * this.remoteOrators.length
        //     // )
        //   }
        // } else {
        //   if (oratorIsLive) {
        //     // setTimeout(
        //     orator.streamControls?.muteRemoteAudio()
        //     // 500 + 200 * this.remoteOrators.length
        //     // )
        //   } else {
        //     orator.streamControls?.unmuteRemoteAudio()
        //   }
        // }
      })
    },
    onStopPublished() {
      console.log('[onStopPublished]')
      this.changeRoom({
        room: 'waiting',
        orators: this.remoteOrators.map((orator) =>
          decodeURIComponent(orator.name)
        ),
      })
      this.sceneUpdate({ orators: [] })
      this.stopPreviewStream()
    },
    changeRoom({
      sceneOrators,
      hasVideo = false,
      hasPresentation = false,
      hasScreenSharing = false,
    }) {
      console.log('[changeRoom]')
      sceneOrators = sceneOrators?.map((orator) => decodeURIComponent(orator))

      console.log(sceneOrators)

      this.setCurrentRoom(
        sceneOrators?.includes(this.localOrator.name) ? 'live' : 'waiting'
      )

      // this.presentation.totalPages = null
      // this.presentation.id = null
      // this.presentation.origins = []

      this.allOrators.forEach((orator) => {
        const oratorIsLive = sceneOrators?.includes(orator.name)
        if (!oratorIsLive) {
          orator.visible = true
          // orator.streamControls?.unmuteRemoteAudio()

          const video = document.getElementById(orator.streamControls?.id())
          video?.play()

          const video2 = this.$refs.participant
            .find((el) => el.dataset.name === orator.name)
            ?.querySelector('video')
          video2?.pause()
        } else {
          if (this.currentRoom === 'waiting') {
            orator.visible = false
            // orator.streamControls?.muteRemoteAudio()
          }
          const video = document.getElementById(orator.streamControls?.id())
          video?.pause()
        }
      })
      this.translatePreviewStream()

      this.sceneUpdate({
        orators: sceneOrators,
        hasVideo,
        hasPresentation,
        hasScreenSharing,
      })
      // }

      // this.session
      //   .join({ name: this.$store.state.query.workspace })
      //   .on(ROOM_EVENT.STATE, (room) => {
      //     this.room = room
      //     this.publishLocalMedia(room)
      //   })
      //   .on(ROOM_EVENT.MESSAGE, (message) => {
      //     const event = JSON.parse(message.text)

      //     console.log(event)

      //     switch (event.type) {
      //       case MESSAGE_EVENTS.CHANGE_ROOM:
      //         if (event.payload.room === 'waiting') {
      //           this.changeRoomToWaiting()
      //         }
      //         break
      //     }
      //   })
    },
    changeRoomToWaiting() {
      console.log('[changeRoomToWaiting]')
      // this.room.leave()

      this.remoteOrators = []

      this.presentation.totalPages = null
      this.presentation.id = null
      this.presentation.origins = []

      setTimeout(() => {
        console.log('CHANGE ROOM --- WAITING')

        // this.joinWaitingRoom()

        this.setCurrentRoom('waiting')

        //   this.session
        //     .join({
        //       name: this.$store.state.query.workspace + '_waitingroom',
        //     })
        //     .on(ROOM_EVENT.STATE, (room) => {
        //       this.room = room
        //       this.publishLocalMedia(room)
        //       this.showWaitingRoomParticipants()
        //     })
      }, 1000)
    },
    onPublishedRemoteOrator(participant, participant_stream) {
      console.log('[onPublishedRemoteOrator]')
      // if (!remoteOrator) {
      //   const remoteOrator = new RemoteOrator()
      //   remoteOrator.name = decodeURIComponent(participant.name())
      //   this.remoteOrators.push(remoteOrator)
      // }

      const remoteOrator = this.remoteOrators.find(
        (orator) => orator.name === decodeURIComponent(participant.name())
      )

      // let display = null

      // if (this.currentRoom === 'waiting') {
      const display = this.$refs.orators.$refs.orator?.find((el) => {
        return el.dataset.name === decodeURIComponent(participant.name())
      })
      // } else {
      //   display = this.$refs.participant.find((el) => {
      //     return el.dataset.name === decodeURIComponent(participant.name())
      //   })
      //   // return
      // }

      if (!display || display.querySelector('video')) {
        return
      }

      // const oratorVideos = display.getElementsByTagName('video')
      // if (oratorVideos) {
      //   Array.from(oratorVideos).forEach((video) => {
      //     if (video) {
      //       // participant_stream.stop()
      //       video.pause()
      //       video.srcObject = null
      //       video.src = ''
      //       video.remove()
      //     }
      //   })
      // }

      participant_stream
        .play(display, this.options)
        .on(STREAM_EVENT, (streamEvent) => {
          console.log(streamEvent, 'STREAM EVENT')
          switch (streamEvent.type) {
            case STREAM_EVENT_TYPE.VIDEO_MUTED:
              this.onVideoMuted(remoteOrator, false)
              break
            case STREAM_EVENT_TYPE.VIDEO_UNMUTED:
              this.onVideoMuted(remoteOrator, true)
              break
          }
        })
        .on(STREAM_STATUS.PENDING, (stream) => {
          console.log(
            `${STREAM_STATUS.PENDING} content stream of ${decodeURIComponent(
              participant.name()
            )}`,
            stream,
            stream.id()
          )
        })
        .on(STREAM_STATUS.PLAYING, (stream) => {
          console.log(
            `${STREAM_STATUS.PLAYING} content stream of ${decodeURIComponent(
              participant.name()
            )}`,
            stream,
            stream.id()
          )

          // stream.setVolume(0);

          const remoteOrator = this.remoteOrators.find(
            (orator) => orator.name === decodeURIComponent(participant.name())
          )

          // stream.muteRemoteAudio()
          remoteOrator.streamControls = stream
          // remoteOrator.streamControls.muteRemoteAudio()
          remoteOrator.isVideoOn = !(
            remoteOrator.streamControls.getVideoState()?.muted || false
          )
          remoteOrator.isMicOn = !(
            remoteOrator.streamControls.getAudioState()?.muted || false
          )
          if (
            this.currentSceneOrators?.includes(this.localOrator.name) &&
            this.currentSceneOrators?.includes(remoteOrator.name)
          ) {
            remoteOrator.streamControls.unmuteRemoteAudio()
          }
          if (
            !this.currentSceneOrators?.includes(this.localOrator.name) &&
            !this.currentSceneOrators?.includes(remoteOrator.name)
          ) {
            remoteOrator.streamControls.unmuteRemoteAudio()
          }

          const video = document.getElementById(stream.id())

          if (video) {
            // video.load()
            // remoteOrator.stream = video.srcObject
            // remoteOrator.audioSourceMic =
            //   this.$store.state.audioContext.createMediaStreamSource(
            //     video.srcObject
            //   )

            // const display = this.$refs.participant
            //   .find((el) => {
            //     return (
            //       el.dataset.name === decodeURIComponent(participant.name())
            //     )
            //   })
            //   .querySelector('video')

            // display.srcObject = video.srcObject.clone()
            // display.play()
            // }

            setTimeout(() => {
              if (
                this.currentRoom === 'live' &&
                this.currentSceneOrators.includes(this.localOrator.name) &&
                this.currentSceneOrators.includes(remoteOrator.name)
              ) {
                video.pause()
                // display.play()
              }
            }, 3000)
          }
        })
    },
    stopPreviewStream() {
      console.log('[stopPreviewStream]')
      if (this.isMobileDevice) {
        const video = document.getElementById(this.streamPreview?.id())
        video?.pause()
      } else {
        const preview = document.getElementById('sourcePreview')
        preview.pause()
      }
      this.streamPreview?.muteRemoteAudio()
      this.sourcePreview = false
    },
    translatePreviewStream() {
      console.log('[translatePreviewStream]')

      const video = document.getElementById(this.streamPreview?.id())
      if (!video) {
        return
      }

      if (this.isMobileDevice) {
        video.play().then(() => {
          this.streamPreview.unmuteRemoteAudio()
        })
      } else {
        const preview = document.getElementById('sourcePreview')
        preview.srcObject = video.srcObject.clone()
        preview.play().then(() => {
          this.streamPreview.muteRemoteAudio()
        })
      }
      this.sourcePreview = true
    },
    // setActiveSceneVisibility({
    //   sceneParticipants,
    //   hasVideo = false,
    //   hasPresentation = false,
    //   hasScreenSharing = false,
    // }) {
    //   this.remoteOrators.forEach((orator) => {
    //     orator.visible = false
    //     orator.streamControls?.muteRemoteAudio()
    //     const video = document.getElementById(orator.streamControls?.id())
    //     video?.pause()
    //   })

    //   this.stopPreviewStream()

    //   // if (!this.isMobileDevice) {
    //   //   this.streamPreview?.muteRemoteAudio()
    //   // }

    //   const remoteParticipants = sceneParticipants.map((el) =>
    //     decodeURIComponent(el)
    //   )

    //   if (hasVideo || hasScreenSharing) {
    //     translatePreviewStream()
    //     this.remoteOrators.forEach((orator) => {
    //       orator.visible = false
    //       orator.streamControls.muteRemoteAudio()
    //       const video = document.getElementById(orator.streamControls.id())
    //       video?.pause()
    //     })
    //   }

    //   if (hasPresentation) {
    //     this.remoteOrators.forEach((orator) => {
    //       orator.visible = false
    //       orator.streamControls?.muteRemoteAudio()
    //       const video = document.getElementById(orator.streamControls.id())
    //       video?.pause()
    //     })
    //     if (!remoteParticipants.includes(this.localOrator.name)) {
    //       translatePreviewStream()
    //     }
    //   } else {
    //     if (
    //       (this.activeScenePayload &&
    //         remoteParticipants.length === 1 &&
    //         new RegExp('is-host').test(remoteParticipants[0])) ||
    //       (remoteParticipants.length > 1 &&
    //         !remoteParticipants.includes(this.localOrator.name))
    //     ) {
    //       translatePreviewStream()
    //       this.remoteOrators.forEach((orator) => {
    //         orator.visible = false
    //         orator.streamControls?.muteRemoteAudio()
    //         const video = document.getElementById(orator.streamControls.id())
    //         video?.pause()
    //         // if (remoteParticipants.includes(orator.name)) {
    //         //   orator.audioSourceMic.connect(this.mainAudioMix);
    //         // }
    //       })
    //     } else {
    //       let orators = remoteParticipants.map((el) =>
    //         el.replace('is-host', '')
    //       )
    //       if (remoteParticipants.length > 0) {
    //         this.remoteOrators.forEach((orator) => {
    //           if (orators.includes(orator.name)) {
    //             if (
    //               orator.name !== this.localOrator.name ||
    //               !this.activeScenePayload
    //             ) {
    //               orator.visible = true
    //             }
    //             orator.streamControls?.unmuteRemoteAudio()
    //             const video = document.getElementById(
    //               orator.streamControls?.id()
    //             )
    //             video?.play()
    //           } else {
    //             if (
    //               orator.name !== this.localOrator.name ||
    //               !this.activeScenePayload
    //             ) {
    //               orator.visible = false
    //             }
    //             orator.streamControls?.muteRemoteAudio()
    //             const video = document.getElementById(
    //               orator.streamControls.id()
    //             )
    //             video?.pause()
    //           }
    //         })
    //       } else {
    //         translatePreviewStream()
    //         this.remoteOrators.forEach((orator) => {
    //           orator.visible = false
    //           orator.streamControls?.muteRemoteAudio()
    //           const video = document.getElementById(orator.streamControls.id())
    //           video?.pause()
    //         })
    //       }
    //     }
    //   }

    //   // this.$nextTick(() => {
    //   //   let orators = remoteParticipants.map((el) => el.replace("is-host", ""));
    //   //   this.remoteOrators.forEach((orator) => {
    //   //     if (this.connectedOrators.includes(orator.name)) {
    //   //       try {
    //   //         orator.audioSourceMic.disconnect(this.mainAudioMix);
    //   //         this.connectedOrators.splice(this.connectedOrators.indexOf(orator.name), 1);
    //   //       } catch (e) {
    //   //         console.log(e);
    //   //       }
    //   //     }
    //   //     if (orators.includes(orator.name)) {
    //   //       console.log(orator.audioSourceMic);
    //   //       orator.audioSourceMic.connect(this.mainAudioMix);
    //   //       this.connectedOrators.push(orator.name);
    //   //     }
    //   //   });
    //   // });
    // },
    async publishLocalMedia(room) {
      console.log('[publishLocalMedia]')
      try {
        console.log('LOCALMEDIA')
        const display = this.$refs.display
        let constraints
        if (this.getCanvasStream && this.getBgType === 'blur') {
          constraints = {
            audio: false,
            video: false,
            customStream: this.getCanvasStream.clone(),
          }
        } else {
          constraints = {
            audio: this.localOrator.stream.getAudioTracks()[0]?.getSettings(),
            video: this.localOrator.stream.getVideoTracks()[0].getSettings(),
          }
        }
        this.myStream = await room
          .publish({
            name: encodeURIComponent(this.localOrator.name),
            display: display,
            // videoContentHint: 'motion',
            transport: 'TCP',
            // constraints: {
            //   // audio: this.getStream.getAudioTracks()[0].getSettings(),
            //   // video: this.getStream.getVideoTracks()[0].getSettings(),
            //   customStream: customStream,
            // },
            constraints,
            record: false,
            receiveVideo: false,
            receiveAudio: false,
            sdpHook: this.sdpHook,
          })
          .on(STREAM_STATUS.FAILED, (stream) => {
            console.warn('Local stream failed!', stream)
            // setStatus("#localStatus", stream.status());
            // onMediaStopped(room);
          })
          .on(STREAM_STATUS.PUBLISHING, (stream) => {
            console.warn('Local stream PUBLISHING!', stream.name())
            // setStatus("#localStatus", stream.status());
            // onMediaPublished(stream);
            // if (!this.isMobileDevice) {
            this.streamPreview?.muteRemoteAudio()

            if (!this.isMicOn) {
              stream.muteAudio()
              this.myStream?.muteAudio()
            }
            this.hideLoader()
            // }
          })
          .on(STREAM_STATUS.UNPUBLISHED, (stream) => {
            console.warn('Local stream UNPUBLISHED!', stream)
            // setStatus("#localStatus", stream.status());
            // onMediaStopped(room);
          })
      } catch (e) {
        console.log(e)
      }
    },
    videoToggle(state) {
      const videoTrack = this.localOrator?.stream?.getVideoTracks()
      if (videoTrack) {
        videoTrack[0].enabled = state
      }
      this.toggleVideo(state)
      this.localOrator.isVideoOn = state
      if (state) {
        this.myStream.unmuteVideo()
      } else {
        this.myStream.muteVideo()
      }
    },
    micToggle(state) {
      this.toggleMic(state)
      const audioTrack = this.localOrator?.stream?.getAudioTracks()
      audioTrack[0].enabled = this.isMicOn
      if (state) {
        this.myStream.unmuteAudio()
      } else {
        this.myStream.muteAudio()
      }
    },
    async getConnectionSettings() {
      try {
        const {
          data: { data },
        } = await axios
          .post(`${resourceMain.API.api_studio.root}speakers/validate`, {
            workspace: this.$store.state.query.workspace,
            stream_key: this.$store.state.query.key,
          })
          .catch(() => {
            this.showLoader()
            this.$awn.alert(
              'Ошибка прав доступа. Пожалуйста, попробуйте обновить страницу или обратитесь в поддержку'
            )
          })

        this.localOrator = new LocalOrator({
          name: decodeURIComponent(`${data.name}-${data.id}`),
        })
        this.socketServerURL = `wss://${data?.video_settings.origin}:8443`

        let pc = document.createElement('script')
        pc.type = 'text/javascript'
        pc.async = true
        pc.src = `https://${resourceMain.PROOFIX_CHAT.url}`
        pc.onload = () => {
          this.initWidgetHC({
            selector: 'hyper-chat',
            widgetId: data?.chat_settings?.json_settings?.widget_id,
            xid: data?.chat_settings?.json_settings?.channel_id,
            chatAuth: data?.chat_settings?.auth,
          })
        }
        let s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(pc, s.nextSibling)
      } catch (e) {
        console.log('get connection settings error: ', e)
      }
    },
    initWidgetHC({ selector, widgetId, xid, chatAuth }) {
      let params = {
        selector: `#${selector}`,
        widgetId: widgetId,
        channelId: xid,
        ssoAuth: chatAuth,
        mode: resourceMain.PROOFIX_CHAT.mode,
      }
      const chat = new window.ProofixChat(params)
      chat.init()
    },
    async onSettingsChange() {
      // this.localOrator.stream = await this.getAccessToDevices()
      // this.localOrator.audioSourceMic =
      //   this.$store.state.audioContext.createMediaStreamSource(
      //     this.localOrator.stream
      //   )
      // await this.myStream.stop()
      // this.publishLocalMedia(this.room)
      Promise.all([
        await this.getAccessToDevices().then((res) => {
          this.localOrator.stream = res
        }),
        await this.session.disconnect(),
        this.connect(),
      ])
      console.log('onSettingsChange')
    },
    toggleChatPanel() {
      this.isChatPanelVisible = !this.isChatPanelVisible
    },
    onVideoMuted(orator, value) {
      orator.isVideoOn = value
    },
  },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.test {
  height: 190px;
  width: 340px;
  border: 1px solid rgb(185, 185, 195);
  border-radius: 5px;
  background-color: rgb(0, 0, 0);
}

.the-stream {
  height: 100%;
  width: 100%;

  &__column_preview {
    width: 370px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  // &__chat {
  //   overflow-y: auto;
  //   overflow-x: hidden;
  //   padding: 10px;
  //   border-radius: $border-radius;
  //   min-width: 200px;
  //   width: 370px;
  //   background-color: white;
  // }

  &__container {
    height: 100%;
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: center;
  }

  &__column {
    height: 100%;

    &_menu {
      width: 54px;
    }

    &_submenu {
      min-width: 220px;
      width: 220px;
    }

    &_player {
      width: 33%;
      max-width: 1280px;
      padding: 0 12px 12px;
      flex-shrink: 1;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;

      // @media (max-width: 499px) {
      //   height: calc(100% - 40px);
      // }

      // @media (min-width: 500px) and (max-width: 1000px) {
      //   display: flex;
      //   flex-direction: row;
      //   height: 100%;
      // }
    }

    &_preview {
      width: 370px;
    }
  }

  &__chat {
    background: #fff;
    border-top: 1px solid #ddd;
    padding: 15px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    // @media (min-width: 500px) and (max-width: 1000px) {
    //   bottom: -11px;
    //   // margin: 0 -29px;
    //   // width: calc(100% + 58px);
    //   padding: 5px 15px;
    //   left: 10px;
    //   width: calc(100% - 10px);
    // }

    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;

      & span {
        margin-left: 5px;
      }
    }

    &-icon {
      margin: 0 !important;
    }
  }

  &__player-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 12px;
    margin-bottom: 20px;

    // @media (min-width: 500px) and (max-width: 1000px) {
    //   justify-content: flex-start;
    //   margin: 0 auto;
    //   position: relative;
    //   gap: 5px;
    //   width: 100%;
    // }
  }

  .orator-display {
    max-width: 200px;
    height: 110px;
    margin: 0 auto;
    border-radius: $border-radius;
    border: 1px solid #b9b9c3;
    overflow: hidden;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    background-color: #000;

    &.show-background {
      background: url('~@/images/account_circle.png') center center no-repeat;
      background-size: cover;

      video {
        visibility: hidden;
      }
    }

    video {
      height: 105%;
      object-fit: contain;
    }
  }

  &__canvas {
    display: none;
  }

  &__video-container {
    max-height: 90%;
    &__item {
      width: 340px;
      height: 190px;
      background: black;
      border-radius: $border-radius;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      margin-bottom: 8px;

      video {
        height: 105%;
        object-fit: contain;
      }

      &__name {
        position: absolute;
        bottom: 5px;
        left: 5px;
        color: white;
      }

      &.show-background {
        background: url('~@/images/account_circle.png') center center no-repeat;
        background-size: cover;

        video {
          visibility: hidden;
        }
      }
    }

    &__remote-orators {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 8px;
    }

    &__presentation-image,
    &__stream-preview {
      // height: 95%;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 8px;

      img {
        max-height: 55vh;
        &.hidden {
          display: none;
        }
      }

      video {
        height: 100%;
        width: 100%;
        border-radius: $border-radius;
      }
    }

    &__presentation-image {
      position: relative;
      .controls-wrapper {
        opacity: 0.3;
        position: absolute;
        bottom: 24px;
        left: calc(50% - 57px);
        transition: 0.5s;
      }
      &:hover {
        .controls-wrapper {
          opacity: 1;
        }
      }
      img {
        max-width: 100%;
      }
    }
  }

  &__video {
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    border: 1px solid #b9b9c3;
    border-radius: 5px;
  }

  &__local-display {
    height: 190px;
    width: 340px;
    margin: 0 auto;
    border: 1px solid #b9b9c3;
    border-radius: $border-radius;
    background-color: #000000;
    overflow: hidden;

    video {
      width: 102%;
      height: 102%;
      transform: translate(-1%, -1%);
    }
  }

  &__modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.7);
  }

  &__settings {
    margin: 0 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;

    // @media (min-width: 500px) and (max-width: 1000px) {
    //   order: 1;
    // }
  }

  &__scenes {
    flex-grow: 1;
    flex-shrink: 1;
    max-height: 150px;
  }

  &__container-mobile {
    height: calc(100% - 54px);
    .the-stream {
      &__menu-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
      }

      &__video-container {
        height: calc(100vw / 16 * 9);
        &__stream-preview {
          height: 100%;
          margin: 0;
        }
        &__item {
          width: 48%;
          height: 100px;
          overflow: hidden;
        }
        &__presentation-image {
          height: 100%;
          margin: 0;

          img {
            border-radius: $border-radius;
          }

          .controls-wrapper {
            position: static;
            visibility: visible;
            margin-top: 12px;
          }
        }
      }

      &__orators {
        height: calc(100% - 56.25vw);
      }

      &__chat {
        width: 100%;
        height: calc(100% - 58px - 57px - (100vw / 16 * 9));
        position: absolute;
        bottom: 58px;
        left: 0;
        background: #fff;
        padding: 0;

        .chat {
          overflow: visible;
          height: calc(100% - 38px);
          &__title {
            height: 38px;
            display: flex;
            justify-content: space-between;
            background: #f2f0f6;
            align-items: center;
            padding-left: 14px;
          }
          &__close {
            padding: 8px;
          }
          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 10px;
            background: #f2f0f6;
          }
        }
      }
    }
  }
}

.chat {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
</style>
