<template lang="pug">
div.layout-main
  v-loader(:is-show='getLoaderShow')
  div.layout-main__header
    div.layout-main__logo
      span {{ appVersion }}
    div.layout-main__header-container
      slot(name="header") 
        router-view(name="header")
  div.layout-main__body
    slot
      router-view
</template>

<script>
import { version } from '../../package.json'
import VLoader from '@/components/v-loader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'LayoutMain',
  components: {
    VLoader,
  },
  computed: {
    ...mapGetters(['getLoaderShow']),
    appVersion() {
      return `v${version}`
    },
  },
}
</script>

<style lang="scss">
.layout-main {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    flex-grow: 0;
    height: 62px;
    background-color: white;
    border-bottom: 1px solid #ebe9f1;
    border-radius: 0 0 6px 6px;
    padding: {
      left: 24px;
      right: 24px;
    }

    @media (max-width: 800px) {
      height: 57px;
      padding: {
        left: 14px;
        right: 10px;
      }
    }
  }

  &__logo {
    height: 33px;
    width: 112px;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
    background-image: url('~@/assets/logo.png');

    & span {
      position: absolute;
      top: 38px;
      left: 112px;
      color: grey;
      font-size: 10px;

      @media (max-width: 800px) {
        left: 100px;
      }
    }
  }

  &__body {
    height: 50%;
    flex-shrink: 1;
    flex-grow: 1;
  }
}
</style>
