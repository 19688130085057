module.exports = {
  NODE_ENV: process.env.NODE_ENV,
  KEYCLOAK: {
    url: 'https://auth.dev.proofix.ru/auth',
    realm: 'proofix',
    clientId: 'frontend',
    onLoad: 'login-required', // [check-sso | login-required]
    redirectUri: 'http://my.dev.proofix.ru/',
  },
  API: {
    main: {
      root: 'https://api.dev.proofix.ru/api/',
      static: process.env.STATIC_RESOURCE || false,
    },
    api_studio: {
      root: 'https://api-studio.dev.proofix.ru/api/',
      static: process.env.STATIC_RESOURCE || false,
    },
  },
  PROOFIX_CHAT: {
    url: 'proofix-dev.website.yandexcloud.net/widgets/chat/proofix-chat.js?v1.00.14',
    mode: 'dev',
  },
}
