<template lang="pug">
div.v-loader(v-show='isShow')
  div.v-loader__content
    div.v-loader__spinner
    div.v-loader__text(v-if="text.length") 
      span {{text}}
</template>

<script>
export default {
  name: 'VLoader',
  props: {
    isShow: {
      type: Boolean,
      default: false,
      required: true,
    },
    text: {
      type: String,
      default: '',
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.v-loader {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(240, 240, 240, 0.3);
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  &__spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #eee;
    border-top: 5px solid #6e6b7b;
    border-radius: 50%;
    animation: v-loader 2s linear infinite;
  }
  &__text {
    padding: 1rem 0.5rem;
    flex-wrap: wrap;
    text-align: center;
  }
}

@keyframes v-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
