import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import LayoutMain from '../layout/layout-main.vue'
import TheStream from '../views/the-stream.vue'
import TheSettings from '../views/the-settings.vue'
import VTranslationControl from '../components/v-translation-control.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/settings',
    component: TheSettings,
    name: 'settings',
  },
  {
    path: '/',
    component: LayoutMain,
    children: [
      {
        name: 'stream',
        path: '',
        components: {
          default: TheStream,
          header: VTranslationControl,
        },
      },
    ],
  },
]

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.query.workspace && to.query.key) {
    store.commit('UPDATE_QUERY_DATA', to.query)
  }
  if (to.path !== '/settings' && store.state.isSettingsModalVisible) {
    next({
      name: 'settings',
      query: { workspace: to.query.workspace, key: to.query.key },
    })
  } else next()
})

export default router
