export default {
  namespaced: true,
  state: () => ({
    currentRoom: 'waiting',
  }),
  getters: {
    getCurrentRoom: (state) => state.currentRoom,
  },
  actions: {
    setCurrentRoom({ commit }, currentRoom) {
      commit('SET_CURRENT_ROOM', currentRoom)
    },
  },
  mutations: {
    SET_CURRENT_ROOM(state, currentRoom) {
      state.currentRoom = currentRoom
    },
  },
}
