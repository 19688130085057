export default {
  PRESENTATION_PAGE_FORWARD: 'presentation_page_forward',
  PRESENTATION_PAGE_BACKWARD: 'presentation_page_backward',
  PRESENTATION_DISAPPEAR: 'presentation_disappear',
  PRESENTATION_APPEAR: 'presentation_appear',
  ACTIVE_SCENE_UPDATE: 'active_scene_update',
  MUTE_ORATOR: 'mute_orator',
  UNMUTE_ORATOR: 'unmute_orator',
  CHANGE_ROOM: 'change_room',
  RENAME: 'rename',
  STOP_PUBLISHING: 'stop_publishing',
}
