import Vue from 'vue'
import Vuex from 'vuex'
import devices from './modules/devices'
import studio from './modules/studio'
import video from './modules/video'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isStreaming: true,
    isSharingScreen: false,
    isStartMoveOrator: false,
    isStartMoveDecoration: false,
    isStartMoveBackground: false,
    isSettingsModalVisible: true,
    audioContext: null,
    query: {
      workspace: null,
      key: null,
    },
    isLoaderShow: true,
  },
  getters: {
    getLoaderShow: (state) => state.isLoaderShow,
  },
  mutations: {
    UPDATE_STREAM_STATUS(state, status = false) {
      state.isStreaming = status
    },
    UPDATE_SHARING_SCREEN_STATUS(state, status = false) {
      state.isSharingScreen = status
    },
    UPDATE_MOVE_ORATOR_STATUS(state, status = false) {
      state.isStartMoveOrator = status
    },
    UPDATE_MOVE_DECORATION_STATUS(state, status = false) {
      state.isStartMoveDecoration = status
    },
    UPDATE_MOVE_BACKGROUND_STATUS(state, status = false) {
      state.isStartMoveBackground = status
    },
    UPDATE_MODAL_SETTINGS_VISIBLE(state, status = true) {
      state.isSettingsModalVisible = status
    },
    UPDATE_AUDIO_CONTEXT(state, payload) {
      state.audioContext = payload
    },
    UPDATE_QUERY_DATA(state, payload) {
      state.query = payload
    },
    SET_SHOW_LOADER(state, isShow) {
      state.isLoaderShow = isShow
    },
  },
  actions: {
    showLoader({ commit }) {
      commit('SET_SHOW_LOADER', true)
    },
    hideLoader({ commit }) {
      commit('SET_SHOW_LOADER', false)
    },
  },
  modules: {
    devices,
    studio,
    video,
  },
})
