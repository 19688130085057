import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import VIcon from './components/v-icon'
import VueAWN from 'vue-awesome-notifications'
import {
  detectMobileDevice,
  detectMobileDeviceBaseOnScreenWidth,
  detectIOS,
} from './helpers/detectMobileDevice'

Vue.component(VIcon.name, VIcon)

let options = {}
Vue.use(VueAWN, options)

Vue.prototype.$isMobile =
  detectMobileDevice() || detectMobileDeviceBaseOnScreenWidth()
Vue.prototype.$isIOS = detectIOS()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
