<template lang="pug">
v-settings-change(:showCloseIcon='false')
</template>

<script>
import { VirtualBg } from '@/modules/virtual-bg'
import { mapActions } from 'vuex'

export default {
  name: 'TheSettings',
  components: {
    VSettingsChange: () => import('../components/v-settings-change.vue'),
  },
  created() {
    const options = {
      virtualBg: VirtualBg,
      holstOptions: {
        width: this.$route.query.holstWidth,
        height: this.$route.query.holstHeight,
      },
      selfieMode: this.$route.query.selfieMode,
      filter: this.$route.query.bgFilter,
    }
    this.initVirtualBg(options)
  },
  methods: {
    ...mapActions('video', ['initVirtualBg']),
  },
}
</script>
