export default {
  namespaced: true,
  state: () => ({
    canvasStream: null,
    cameraStream: null,
    stream: null,
    virtualBg: '',
    bgType: 'default',
    bgFilter: 'blur(10px)',
    useBgEffects: false,
  }),
  getters: {
    getVirtualBgStream: (state) => state.canvasStream,
    getCameraStream: (state) => state.cameraStream,
    getCanvasStream: (state) => state.canvasStream,
    getStream: (state) => state.stream,
    getBgType: (state) => state.bgType,
    getUseBgEffects: (state) => state.useBgEffects,
    getVirtualBg: (state) => state.virtualBg,
  },
  actions: {
    initVirtualBg({ commit }, options) {
      console.log('initVirtualBg')
      commit('SET_VIRTUAL_BG', options.virtualBg)
      commit('SET_BG_FILTER', 'blur(10px)')
    },
    changeHolstOption({ state }, height) {
      const width = Math.round(height * 1.777777)
      state.virtualBg.holstOptions = {
        width,
        height,
      }
    },
    async startVirtualBg({ commit, state, dispatch }, stream) {
      if (!state.canvasStream) {
        await state.virtualBg.initSegmentBackground(stream)
      } else {
        state.virtualBg.changeMediaStream(stream)
      }
      commit('SET_CANVAS_STREAM', state.virtualBg.stream)
      commit('SET_CAMERA_STREAM', stream)
      dispatch('changeMediaStream')
    },
    async resumeVirtualBgStream({ state }) {
      await state.virtualBg.resumeSegmentBackground()
    },
    pauseVirtualBgStream({ state }) {
      state.virtualBg.pauseSegmentBackground()
    },

    changeMediaStream({ state, commit }) {
      if (state.bgType === 'default') {
        commit('SET_STREAM', state.cameraStream)
      }
      if (state.bgType === 'blur') {
        commit('SET_STREAM', state.virtualBg.stream)
      }
    },

    changeVideoBg({ state, commit, dispatch }, bgType) {
      dispatch('changeMediaStream')
      if (bgType === 'default') {
        state.virtualBg.applyFilter(state.bgFilter)
      }
      if (bgType === 'blur') {
        state.virtualBg.applyFilter(state.bgFilter)
      }
      commit('SET_BG_TYPE', bgType)
    },
    setUseBgEffects({ commit }, status) {
      commit('SET_USE_BG_EFFECTS', status)
    },
  },
  mutations: {
    SET_VIRTUAL_BG(state, virtualBg) {
      state.virtualBg = new virtualBg()
    },
    SET_CANVAS_STREAM(state, stream) {
      state.canvasStream = stream
    },
    SET_CAMERA_STREAM(state, stream) {
      state.cameraStream = stream
    },
    SET_STREAM(state, stream) {
      state.stream = stream
    },
    SET_BG_TYPE(state, bgType) {
      state.bgType = bgType
    },
    SET_BG_FILTER(state, bgFilter) {
      state.bgFilter = bgFilter
    },
    SET_USE_BG_EFFECTS(state, status) {
      state.useBgEffects = status
    },
  },
}
